import React from 'react';
import ImgWithFallback from 'components/ImgWithFallback';

const ACTButton = ({
	accesibilityTitle,
	Icon,
	IconURL,
	visibleText,
	...rest
}) => {
	return (
		<div className="ACTButton">
			<button
				title={accesibilityTitle}
				aria-label={accesibilityTitle}
				{...rest}>
				{Icon && <Icon aria-hidden="true" focusable="false" />}
				{IconURL && (
					<ImgWithFallback
						img={IconURL}
						type="image/svg+xml"
						aria-hidden="true"
						focusable="false"
					/>
				)}
				{visibleText ? (
					visibleText
				) : (
					<span className="sr-only">{accesibilityTitle}</span>
				)}
			</button>
		</div>
	);
};

export default ACTButton;
