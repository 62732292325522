import React from 'react';

const Icon = ({ icons, className, id, ...rest }) => {
	return (
		<svg className={`icon ${className}`} {...rest}>
			<use xlinkHref={`${icons}#${id}`} />
		</svg>
	);
};

export default Icon;
