/* eslint-disable indent */

import {
	ON_CHANGE_LANGUAGE_PROVIDER,
	ON_THEME_COLOR_CHANGE,
	ON_FULLPAGE_CHANGE,
} from './constants';

const initialState = {
	languageProvider: false,
	isDarkTheme: false,
	fullpage: false,
};

const appReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case ON_CHANGE_LANGUAGE_PROVIDER:
			return { ...state, languageProvider: { ...payload } };
		case ON_THEME_COLOR_CHANGE:
			return { ...state, isDarkTheme: payload };
		case ON_FULLPAGE_CHANGE:
			return { ...state, fullpage: payload };
		default:
			return state;
	}
};

export default appReducer;
