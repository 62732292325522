import {
	ON_CHANGE_LANGUAGE_PROVIDER,
	ON_THEME_COLOR_CHANGE,
	ON_FULLPAGE_CHANGE,
} from './constants';

const onChangeLanguageProvider = (payload) => {
	return {
		type: ON_CHANGE_LANGUAGE_PROVIDER,
		payload: payload,
	};
};

const onThemeColorChange = (payload) => {
	return {
		type: ON_THEME_COLOR_CHANGE,
		payload: payload,
	};
};
const onFullPagePageChange = (payload) => {
	return {
		type: ON_FULLPAGE_CHANGE,
		payload: payload,
	};
};

export { onChangeLanguageProvider, onThemeColorChange, onFullPagePageChange };
