import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import translationEn from './locales/en';
import translationEs from './locales/es';
import c from 'classnames';
import { SITE_URL } from 'containers/App/constants';

// Components
import ACTLink from 'components/ACTLink';
import ImgWithFallback from 'components/ImgWithFallback';
import Icon from 'components/Icon';
import Loading from 'components/Loading';

// Resources
import ACTFooterIcons from 'assets/icons/genericIcons.svg';
const FooterBackground = `${SITE_URL}/assets/vectors/FooterBackground.svg`;
const ACTLogoDark = `${SITE_URL}/assets/logos/ACTLogoDark.svg`;
const ACTLogoLight = `${SITE_URL}/assets/logos/ACTLogoLight.svg`;

import './Footer.scss';

export default function Footer() {
	const darkLocal = useSelector((state) => state.isDarkTheme);
	const translationNameSpace = 'Footer';
	const { t, i18n } = useTranslation();
	const { pathname } = useLocation();
	if (!i18n.isInitialized) return <Loading />;

	if (
		i18n.isInitialized &&
		!i18n?.hasResourceBundle('en', translationNameSpace)
	) {
		i18n.addResources('en', translationNameSpace, translationEn);
		i18n.addResources('es', translationNameSpace, translationEs);
	}
	return (
		<footer className="Footer">
			<div className="top">
				<ACTLink
					className="header-act-logo"
					href="/"
					accesibilityTitle="ACTotal Logo"
					IconURL={darkLocal ? ACTLogoDark : ACTLogoLight}
				/>
				<div className="footer-links">
					<div
						className={c('footer-link', {
							active: pathname == '/',
						})}>
						<ACTLink
							className="NavMenu-link"
							href="/"
							accesibilityTitle={t(`${translationNameSpace}:home`)}
							visibleText={t(`${translationNameSpace}:home`)}
						/>
					</div>
					<div
						className={c('footer-link', {
							active: pathname == '/who-we-are' || pathname == '/nosotros',
						})}>
						<ACTLink
							className="NavMenu-link"
							href={i18n?.language === 'en' ? '/who-we-are' : '/nosotros'}
							accesibilityTitle={t(`${translationNameSpace}:who-we-are`)}
							visibleText={t(`${translationNameSpace}:who-we-are`)}
						/>
					</div>
					<div
						className={c('footer-link', {
							active: pathname == '/projects' || pathname == '/trabajos',
						})}>
						<ACTLink
							className="NavMenu-link"
							href={i18n?.language === 'en' ? '/projects' : '/trabajos'}
							accesibilityTitle={t(`${translationNameSpace}:projects`)}
							visibleText={t(`${translationNameSpace}:projects`)}
						/>
					</div>
					<div
						className={c('footer-link', {
							active: pathname == '/services' || pathname == '/servicios',
						})}>
						<ACTLink
							className="NavMenu-link"
							href={i18n?.language === 'en' ? '/services' : '/servicios'}
							accesibilityTitle={t(`${translationNameSpace}:services`)}
							visibleText={t(`${translationNameSpace}:services`)}
						/>
					</div>
					<div
						className={c('footer-link', {
							active: pathname == '/contact-us' || pathname == '/contacto',
						})}>
						<ACTLink
							className="NavMenu-link"
							href={i18n?.language === 'en' ? '/contact-us' : '/contacto'}
							accesibilityTitle={t(`${translationNameSpace}:contact`)}
							visibleText={t(`${translationNameSpace}:contact`)}
						/>
					</div>
				</div>
				<div
					className={c('footer-link last', {
						active:
							pathname == '/work-with-us' ||
							pathname == '/trabaja-con-nosotros',
					})}>
					<ACTLink
						className="NavMenu-link"
						href={
							i18n?.language === 'en'
								? '/work-with-us'
								: '/trabaja-con-nosotros'
						}
						accesibilityTitle={t(`${translationNameSpace}:work-with-us`)}
						Icon={(props) => (
							<Icon
								icons={ACTFooterIcons}
								className="icon-header-workWithUs"
								id="workWithUs"
								{...props}
							/>
						)}
						visibleText={t(`${translationNameSpace}:work-with-us`)}
					/>
				</div>
			</div>
			<div className="bottom">
				<p className="copyright">
					© 2024 actotal. <span>All Rights Reserved.</span>
				</p>
				<div className="socials">
					<ACTLink
						className="NavMenu-link socialTwitter"
						Icon={(props) => (
							<Icon
								icons={ACTFooterIcons}
								className="icon-header-twitter"
								id="twitter"
								{...props}
							/>
						)}
						href="https://twitter.com/actotal_tech"
						accesibilityTitle="ACTotal Twitter"
						target="_blank"
					/>

					<ACTLink
						className="NavMenu-link socialLinkedIn"
						Icon={(props) => (
							<Icon
								icons={ACTFooterIcons}
								className="icon-header-linkedin"
								id="linkedin"
								{...props}
							/>
						)}
						href="https://www.linkedin.com/company/actotaltech"
						accesibilityTitle="ACTotal LinkedIn"
						target="_blank"
					/>
					<ACTLink
						className="NavMenu-link socialInstagram"
						Icon={(props) => (
							<Icon
								icons={ACTFooterIcons}
								className="icon-header-instagram"
								id="instagram"
								{...props}
							/>
						)}
						href="https://www.instagram.com/actotal_tech"
						accesibilityTitle="ACTotal Instagram"
						target="_blank"
					/>
					<ACTLink
						className="NavMenu-link socialFacebook"
						Icon={(props) => (
							<Icon
								icons={ACTFooterIcons}
								className="icon-header-facebook"
								id="facebook"
								{...props}
							/>
						)}
						href="https://www.facebook.com/actotaltech/"
						accesibilityTitle="ACTotal Facebook"
						target="_blank"
					/>
				</div>
			</div>
			<ImgWithFallback
				wrapperClassName={'footer-background-one'}
				img={FooterBackground}
				alt={'Footer background'}
				aria-label={'Footer background'}
				loading="eager"
				type="image/svg+xml"
			/>
			<ImgWithFallback
				wrapperClassName={'footer-background-two'}
				img={FooterBackground}
				alt={'Footer background'}
				aria-label={'Footer background'}
				loading="eager"
				type="image/svg+xml"
			/>
		</footer>
	);
}
