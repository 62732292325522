import React, { useState } from 'react';
import cn from 'classnames';
import { SITE_URL } from 'containers/App/constants';
import { useSelector } from 'react-redux';

import NavMenu from './NavMenu';
import ACTButton from 'components/ACTButton';

const HamburguerIconLight = `${SITE_URL}/assets/icons/hamburguerLight.svg`;
const HamburguerIconDark = `${SITE_URL}/assets/icons/hamburguer.svg`;
const CloseIcon = `${SITE_URL}/assets/icons/close.svg`;
const CloseIconLight = `${SITE_URL}/assets/icons/closeLight.svg`;

import './Nav.scss';

export default function Nav({ className }) {
	const [navOpen, setNavOpen] = useState(false);
	const handleOpenMenu = () => {
		if (!navOpen) {
			document.body.classList.add('no-scroll');
			document.querySelector('.NavMenu').classList.add('bg-scroll');
			document.querySelector('.Header').classList.add('bg-mobile');
		} else {
			document.body.classList.remove('no-scroll');
			document.querySelector('.NavMenu').classList.remove('bg-scroll');
			document.querySelector('.Header').classList.remove('bg-mobile');
		}
		setNavOpen(!navOpen);
	};
	const isDarkTheme = useSelector((state) => state.isDarkTheme);
	const themeHamburguerIcon = isDarkTheme
		? HamburguerIconDark
		: HamburguerIconLight;
	const themeCloseIcon = isDarkTheme ? CloseIcon : CloseIconLight;
	return (
		<div className={cn([className, { open: navOpen }])}>
			<ACTButton
				IconURL={navOpen ? themeCloseIcon : themeHamburguerIcon}
				accesibilityTitle="Menu"
				onClick={handleOpenMenu}
				className={'hamburguer-btn'}
				role="button"
				tabIndex="0"
			/>
			<NavMenu className={'NavMenu'} navOpen={navOpen} />
		</div>
	);
}
