import React from 'react';
import ACTButton from 'components/ACTButton';

import './LanguageSwitcher.scss';
import { useTranslation } from 'react-i18next';

export default function LanguageSwitcher() {
	const { i18n } = useTranslation();
	const handleSwitch = async () => {
		if (!i18n.isInitialized) return;
		await i18n.changeLanguage(i18n.translator?.language === 'en' ? 'es' : 'en');
	};
	const lenguage = i18n.translator?.language === 'es' ? 'en' : 'es';

	return (
		<div className="LanguageSwitcher">
			<ACTButton
				id="languageSwitcherBtn"
				className="languageSwitcherBtn"
				onClick={() => handleSwitch()}
				accesibilityTitle="Language Switcher"
				visibleText={lenguage}
			/>
		</div>
	);
}
