import React from 'react';
import ImgWithFallback from 'components/ImgWithFallback';

const ACTLink = ({
	accesibilityTitle,
	Icon,
	IconURL,
	visibleText,
	...rest
}) => {
	return (
		<a title={accesibilityTitle} aria-label={accesibilityTitle} {...rest}>
			{Icon && <Icon aria-hidden="true" focusable="false" />}
			{IconURL && (
				<ImgWithFallback
					img={IconURL}
					type="image/svg+xml"
					aria-hidden="true"
					focusable="false"
				/>
			)}
			{visibleText ? (
				<span>{visibleText}</span>
			) : (
				<span className="sr-only">{accesibilityTitle}</span>
			)}
		</a>
	);
};

export default ACTLink;
