const ON_CHANGE_LANGUAGE_PROVIDER = 'ON_CHANGE_LANGUAGE_PROVIDER';
const ON_THEME_COLOR_CHANGE = 'ON_THEME_COLOR_CHANGE';
const ON_FULLPAGE_CHANGE = 'ON_FULLPAGE_CHANGE';
const SITE_URL = process.env.REACT_APP_CDN ?? 'https://actotal.justfortest.net';

export {
	ON_CHANGE_LANGUAGE_PROVIDER,
	ON_THEME_COLOR_CHANGE,
	ON_FULLPAGE_CHANGE,
	SITE_URL,
};
