import React, { useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import DarkLightToggleIcon from 'components/Icons/DarkLightToggleIcon';
import { useDispatch, useSelector } from 'react-redux';
import { onThemeColorChange } from 'containers/App/actions';

import './ThemeColorToggle.scss';

const ThemeColorToggle = () => {
	const dispatch = useDispatch();
	const isDarkTheme = useSelector((state) => state.isDarkTheme);

	const [darkLocal, setDarkLocal] = useLocalStorage('isDark', false);

	useEffect(() => {
		dispatch(onThemeColorChange(darkLocal));

		document
			.querySelector('body')
			.setAttribute('data-theme', darkLocal ? 'dark' : 'light');
	}, [darkLocal]);

	const handleThemeToggle = () => {
		setDarkLocal(!darkLocal);
		dispatch(onThemeColorChange(!darkLocal));
	};

	return (
		<button
			className="ThemeColorToggle"
			onClick={handleThemeToggle}
			title="Dark/Light Theme Toggle"
			aria-label="Dark/Light Theme Toggle">
			<DarkLightToggleIcon
				themeColorState={isDarkTheme}
				aria-hidden="true"
				focusable="false"
			/>
			<span className="sr-only">Dark/Light Theme Toggle</span>
		</button>
	);
};

export default ThemeColorToggle;
