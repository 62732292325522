import React from 'react';
import './Loading.scss';
const Loading = () => {
	return (
		<main className="loader" role="alert" aria-busy="true">
			<div className="loader" aria-label="Pagina cargando">
				<div className="lds-ellipsis" aria-labelledby="svg-icon-loading">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</main>
	);
};

export default Loading;
