import React from 'react';
import ImgWithFallback from 'components/ImgWithFallback';
import { SITE_URL } from 'containers/App/constants';

const DarkIcon = `${SITE_URL}/assets/icons/darkIcon.svg`;
const LightIcon = `${SITE_URL}/assets/icons/lightIcon.svg`;

const DarkLightToggleIcon = ({ themeColorState }) => {
	return !themeColorState ? (
		<ImgWithFallback img={DarkIcon} type="image/svg+xml" />
	) : (
		<ImgWithFallback img={LightIcon} type="image/svg+xml" />
	);
};

export default DarkLightToggleIcon;
