import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { SITE_URL } from 'containers/App/constants';

// Components
import LanguageSwitcher from 'components/LanguageSwitcher';
import ThemeColorToggle from 'components/ThemeColorToggle';
import ACTLink from 'components/ACTLink';
import Nav from 'components/Nav';

// Resources
const ACTLogoDark = `${SITE_URL}/assets/logos/ACTLogoDark.svg`;
const ACTLogoLight = `${SITE_URL}/assets/logos/ACTLogoLight.svg`;
import './Header.scss';

const Header = () => {
	const darkLocal = useSelector((state) => state.isDarkTheme);

	return (
		<header className="Header">
			<h1 className="header-act-logo-wrapper">
				<ACTLink
					className="header-act-logo"
					href="/"
					accesibilityTitle="ACTotal Logo"
					IconURL={darkLocal ? ACTLogoDark : ACTLogoLight}
				/>
			</h1>
			<ThemeColorToggle />
			<LanguageSwitcher />
			<Nav className="siteNavigation" />
		</header>
	);
};

/* document.addEventListener("DOMContentLoaded", () => {
    window.on("scroll", function() {
        if(window.scrollTop() > 50) {
            document.getElementByClassName("Header").classList.add("bg-color");
        } else {
           document.getElementByClassName("Header").classList.remove("bg-color");
        }
    });
});
 */
/* const Header = () => {
	const darkLocal = useSelector((state) => state.isDarkTheme);
	const[show, setShow] = useState(true)
	const controlNavbar = () =>{
		if(window.scrollY > 100){
			setShow(false)
		}else{
			setShow(true)
		}
	}

	useEffect(() =>{
		window.addEventListener('scroll', controlNavbar)
		return() =>{
			window.removeEventListener('scroll', controlNavbar)
		}
	},[])
 */

/* $(function() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $(".Header").addClass("bg-color");
        } else {
           $(".Header").removeClass("transparent");
        }
    });
}); */

export default memo(() => Header());
