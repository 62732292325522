import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import axe from '@axe-core/react';
import App from './App';
import store from './containers/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

if (process.env.NODE_ENV !== 'production') {
	axe(React, ReactDOM, 1000);
}

ReactDOM.render(
	<>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
