import React, { Suspense } from 'react';
import Loading from 'components/Loading';

export default function WithSuspense(LazyComponent) {
	return (props) => (
		<Suspense fallback={<Loading />}>
			<LazyComponent {...props} />
		</Suspense>
	);
}
