// Dependencies
import React, { memo } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { technologiesHelper as splides } from 'services/technologiesHelper';
// Components
import ImgWithFallback from 'components/ImgWithFallback';
import './FooterHome.scss';
const splideOptions = {
	i18n: {
		prev: 'Previous slide',
		next: 'Next slide',
		first: 'Next slide',
		last: 'Next slide',
		slideX: 'Next slide',
		pageX: 'Next slide',
		play: 'Next slide',
		pause: 'Next slide',
	},

	options: {
		type: 'loop',
		height: '59px',
		gap: '30px',
		autoplay: true,
		arrows: false,
		pagination: false,
		// Para accessibility
		keyboard: true,
		slideFocus: true,
		focusableNodes: 'img',
		pauseOnHover: true,
		pauseOnFocus: true,
		drag: true,
		// ---
		perPage: 9,
		perMove: 1,
		focus: 9,
		breakpoints: {
			768: {
				perPage: 5,
				focus: 5,
				gap: '24px',
			},
			425: {
				perPage: 4,
				focus: 4,
				gap: '20px',
			},
		},
	},
	hasAutoplayProgress: false,
	hasAutoplayControls: false,
};

const FooterHome = () => {
	return (
		<footer>
			<Splide {...splideOptions}>
				{splides.map((slide, key) => (
					<SplideSlide key={key} focusable="false">
						<div className="splide-content">
							<ImgWithFallback
								className={`${slide.name}-logo`}
								img={slide.img}
								fallbackImg={slide.fallbackImg}
								alt={`Technology ${slide.name}`}
								aria-label={`${slide.name} Logo`}
								data-splide-lazy={'nearby'}
							/>
						</div>
					</SplideSlide>
				))}
			</Splide>
		</footer>
	);
};

export default memo(() => FooterHome());
