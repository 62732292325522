import { SITE_URL } from 'containers/App/constants';
// Resources
const AmazonLogoFallback = `${SITE_URL}/assets/images/logos/amazonc.png`;
const AmazonLogo = `${SITE_URL}/assets/images/logos/amazonc.webp`;
const FlutterLogoFallback = `${SITE_URL}/assets/images/logos/flutterc.png`;
const FlutterLogo = `${SITE_URL}/assets/images/logos/flutterc.png`;
const HTML5LogoFallback = `${SITE_URL}/assets/images/logos/html5.png`;
const HTML5Logo = `${SITE_URL}/assets/images/logos/html5.png`;
const JSLogoFallback = `${SITE_URL}/assets/images/logos/js.png`;
const JSLogo = `${SITE_URL}/assets/images/logos/js.png`;
const NodeLogoFallback = `${SITE_URL}/assets/images/logos/nodec.png`;
const NodeLogo = `${SITE_URL}/assets/images/logos/nodec.png`;
const ReactLogoFallback = `${SITE_URL}/assets/images/logos/reactc.png`;
const ReactLogo = `${SITE_URL}/assets/images/logos/react.png`;
const SassLogoFallback = `${SITE_URL}/assets/images/logos/sass.png`;
const SassLogo = `${SITE_URL}/assets/images/logos/sass.png`;
const TestLogoFallback = `${SITE_URL}/assets/images/logos/test.png`;
const TestLogo = `${SITE_URL}/assets/images/logos/test.webp`;
const TypescriptLogoFallback = `${SITE_URL}/assets/images/logos/typescriptc.png`;
const TypescriptLogo = `${SITE_URL}/assets/images/logos/typescriptc.png`;
const PHPLogoFallback = `${SITE_URL}/assets/images/logos/php.png`;
const PHPLogo = `${SITE_URL}/assets/images/logos/php.webp`;
const PuntonetLogoFallback = `${SITE_URL}/assets/images/logos/puntonet.png`;
const PuntonetLogo = `${SITE_URL}/assets/images/logos/puntonet.png`;
const ShopifyLogoFallback = `${SITE_URL}/assets/images/logos/shopify.png`;
const ShopifyLogo = `${SITE_URL}/assets/images/logos/shopify.webp`;
const WoocomerceLogoFallback = `${SITE_URL}/assets/images/logos/woocomerce.png`;
const WoocomerceLogo = `${SITE_URL}/assets/images/logos/woocomerce.webp`;
const WordpressLogoFallback = `${SITE_URL}/assets/images/logos/wordpress.png`;
const WordpressLogo = `${SITE_URL}/assets/images/logos/wordpress.png`;
const AngularLogo = `${SITE_URL}/assets/images/logos/angular.png`;
const SymfonyLogo = `${SITE_URL}/assets/images/logos/Symfony.webp`;
const SymfonyLogoPNG = `${SITE_URL}/assets/images/logos/Symfony.png`;
const PythonLogo = `${SITE_URL}/assets/images/logos/Python.webp`;
const PythonLogoPNG = `${SITE_URL}/assets/images/logos/Python.png`;
const LaravelLogo = `${SITE_URL}/assets/images/logos/Laravel.webp`;
const LaravelLogoPNG = `${SITE_URL}/assets/images/logos/Laravel.png`;
const KotlinLogo = `${SITE_URL}/assets/images/logos/Kotlin.webp`;
const KotlinLogoPNG = `${SITE_URL}/assets/images/logos/Kotlin.png`;
const JavaLogo = `${SITE_URL}/assets/images/logos/Java.webp`;
const JavaLogoPNG = `${SITE_URL}/assets/images/logos/Java.png`;
const ObjectivLogo = `${SITE_URL}/assets/images/logos/Objectiv-c.webp`;
const ObjectivLogoPNG = `${SITE_URL}/assets/images/logos/Objectiv-c.png`;
const SwiftLogo = `${SITE_URL}/assets/images/logos/swift.webp`;
const SwiftLogoPNG = `${SITE_URL}/assets/images/logos/swift.png`;
const GithubLogo = `${SITE_URL}/assets/images/logos/GitHub_Logo.webp`;
const GithubLogoPNG = `${SITE_URL}/assets/images/logos/GitHub_Logo.png`;
const BitbucketLogo = `${SITE_URL}/assets/images/logos/Bitbucket@2x-blue.webp`;
const BitbucketLogoPNG = `${SITE_URL}/assets/images/logos/Bitbucket@2x-blue.png`;
const VuejsLogo = `${SITE_URL}/assets/images/logos/Vue.js.webp`;
const VuejsLogoPNG = `${SITE_URL}/assets/images/logos/Vue.js.png`;
const DockerLogo = `${SITE_URL}/assets/images/logos/docker.webp`;
const DockerLogoPNG = `${SITE_URL}/assets/images/logos/docker.png`;
const HerokuLogo = `${SITE_URL}/assets/images/logos/heroku.webp`;
const HerokuLogoPNG = `${SITE_URL}/assets/images/logos/heroku.png`;
const SvelteLogo = `${SITE_URL}/assets/images/logos/Svelte_Logo.svg.webp`;
const SvelteLogoPNG = `${SITE_URL}/assets/images/logos/Svelte_Logo.svg.png`;
const NextJSLogo = `${SITE_URL}/assets/images/logos/nextjs.webp`;
const NextJSLogoPNG = `${SITE_URL}/assets/images/logos/nextjs.png`;
const GatsbyLogo = `${SITE_URL}/assets/images/logos/gatsby.png`;
const GatsbyLogoPNG = `${SITE_URL}/assets/images/logos/gatsby.png`;
const PreactLogo = `${SITE_URL}/assets/images/logos/preact-seeklogo.png`;
const PreactLogoPNG = `${SITE_URL}/assets/images/logos/preact-seeklogo.png`;

export const technologiesHelper = [
	{
		name: 'Angular',
		img: AngularLogo,
	},
	{
		name: 'Swift',
		img: SwiftLogo,
		fallbackImg: SwiftLogoPNG,
	},
	{
		name: 'Typescript',
		img: TypescriptLogo,
		fallbackImg: TypescriptLogoFallback,
	},
	{
		name: 'Bitbucket',
		img: BitbucketLogo,
		fallbackImg: BitbucketLogoPNG,
	},
	{
		name: 'Python',
		img: PythonLogo,
		fallbackImg: PythonLogoPNG,
	},
	{
		name: 'NextJS',
		img: NextJSLogo,
		fallbackImg: NextJSLogoPNG,
	},
	{
		name: 'Laravel',
		img: LaravelLogo,
		fallbackImg: LaravelLogoPNG,
	},
	{
		name: 'Node',
		img: NodeLogo,
		fallbackImg: NodeLogoFallback,
	},
	{
		name: 'Vuejs',
		img: VuejsLogo,
		fallbackImg: VuejsLogoPNG,
	},
	{
		name: 'Docker',
		img: DockerLogo,
		fallbackImg: DockerLogoPNG,
	},
	{
		name: 'Objectiv',
		img: ObjectivLogo,
		fallbackImg: ObjectivLogoPNG,
	},
	{
		name: 'Heroku',
		img: HerokuLogo,
		fallbackImg: HerokuLogoPNG,
	},
	{
		name: 'Svelte',
		img: SvelteLogo,
		fallbackImg: SvelteLogoPNG,
	},
	{
		name: 'Gatsby',
		img: GatsbyLogo,
		fallbackImg: GatsbyLogoPNG,
	},
	{
		name: 'Preact',
		img: PreactLogo,
		fallbackImg: PreactLogoPNG,
	},
	{
		name: 'Flutter',
		img: FlutterLogo,
		fallbackImg: FlutterLogoFallback,
	},
	{
		name: 'HTML5',
		img: HTML5Logo,
		fallbackImg: HTML5LogoFallback,
	},
	{
		name: 'React',
		img: ReactLogo,
		fallbackImg: ReactLogoFallback,
	},
	{
		name: 'JS',
		img: JSLogo,
		fallbackImg: JSLogoFallback,
	},
	{
		name: 'Github',
		img: GithubLogo,
		fallbackImg: GithubLogoPNG,
	},
	{
		name: 'Sass',
		img: SassLogo,
		fallbackImg: SassLogoFallback,
	},
	{
		name: 'Test',
		img: TestLogo,
		fallbackImg: TestLogoFallback,
	},
	{
		name: 'Amazon',
		img: AmazonLogo,
		fallbackImg: AmazonLogoFallback,
	},
	{
		name: 'Shopify',
		img: ShopifyLogo,
		fallbackImg: ShopifyLogoFallback,
	},
	{
		name: 'Puntonet',
		img: PuntonetLogo,
		fallbackImg: PuntonetLogoFallback,
	},
	{
		name: 'Woocomerce',
		img: WoocomerceLogo,
		fallbackImg: WoocomerceLogoFallback,
	},
	{
		name: 'Wordpress',
		img: WordpressLogo,
		fallbackImg: WordpressLogoFallback,
	},
	{
		name: 'Kotlin',
		img: KotlinLogo,
		fallbackImg: KotlinLogoPNG,
	},
	{
		name: 'PHP',
		img: PHPLogo,
		fallbackImg: PHPLogoFallback,
	},
	{
		name: 'Java',
		img: JavaLogo,
		fallbackImg: JavaLogoPNG,
	},
	{
		name: 'Symfony',
		img: SymfonyLogo,
		fallbackImg: SymfonyLogoPNG,
	},
];

export const getTechnology = (nameTechnology) =>
	technologiesHelper.find((technology) => technology.name === nameTechnology);
